import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import AnimatedHeading from 'components/animated-heading'
import { Separator } from 'components/separator'
import Particles from 'react-particles-js'
import { Design } from '../../constants/design'
import { Heading1, Heading2, Heading3, ParagraphText, ColorSpan } from 'components/text'
import Section from 'components/section'
import { MainContainer, ImageWrapper } from 'components/container'
import { Spacing, Theme } from '../../constants/variables'
import Metadata from '../../constants/metadata'

function serviceItem(props, item, index) {
  const ServiceElement = styled.div`
      padding: ${Theme.spacing.one};
      border-radius: ${Theme.spacing.two};
      margin-top: ${Theme.spacing.one};
  `
  const margin = Theme.spacing.three
  const styles = index === 0 ? {} : { marginTop: margin, marginBottom: margin }
  const illustrationStyles = {
    height: 'auto',
    width: '100%',
    marginRight: '20px'
  }
  const textCols = 8
  const imageCols = 4
  return (<Col md={12} key={`ServiceCol_${index}`}>
    <AnimationContainer animation="fadeInLeft" delay={200} key={`ServiceContainer_${index}`}>
      <ServiceElement key={`ServiceElement_${index}`} style={styles}>
        <Row>
          <Col md={imageCols} style={{ marginBottom: Theme.spacing.two }}>
            <ImageWrapper>
              {item.illustrationTeaser('Illustration', illustrationStyles)}
            </ImageWrapper>
          </Col>
          <Col md={textCols} style={{ margin: 'auto' }}>
            <Heading2 color={Theme.color.primary}>
              {item.header}
            </Heading2>
            <Separator marginBottom={2} />
            <ParagraphText>{item.descriptionTeaser}</ParagraphText>
          </Col>
        </Row>
      </ServiceElement>
    </AnimationContainer>
  </Col>)
}

class ServicesSection extends React.Component {
  shouldComponentUpdate() { return false }

  renderParticles() {
    return (<Particles
      className="particles"
      params={Design.ParticleData} />)
  }

  render() {
    const itemsJSX = Metadata.Services.all.map((item, index) => {
      return serviceItem(this.props, item, index)
    })
    const particles = this.renderParticles()
    const styles = { backgroundColor: Theme.color.black }
    return (
      <Section id="services" style={styles}>
        {particles}
        <MainContainer>
          <Container>
            {Metadata.Services.renderSectionTitle()}
            <Row>
              {itemsJSX}
            </Row>
          </Container>
        </MainContainer>
      </Section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: {eq: "bg2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              src
            }
          }
        }
        web: file(relativePath: {eq: "icons/web.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        mobile: file(relativePath: {eq: "icons/mobile.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        seo: file(relativePath: {eq: "icons/seo2.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        email: file(relativePath: {eq: "icons/email.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        ui: file(relativePath: {eq: "icons/sketch.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        network: file(relativePath: {eq: "icons/network.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }      
      `}
    render={({
      background,
      web,
      mobile,
      seo,
      email,
      ui,
      network }) => <ServicesSection
        background={background}
        web={web}
        mobile={mobile}
        seo={seo}
        email={email}
        ui={ui}
        network={network}
        {...props} />}
  />
)