import React from 'react'
import { Container } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Metadata from '../../constants/metadata'
import { Theme } from '../../constants/variables'
import { Separator } from '../../components/separator'
import Section from '../../components/section'
import LoopVideo from './assets/loop.mp4'
import { Link } from "gatsby"
import { GrowButtonLarge } from '../../components/button'
import { Heading1, Heading3 } from '../../components/text'

class HeroVideo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            height: 0,
            width: 0
        }
    }
    updateDimensions = () => {
        if (this.state.height !== window.innerHeight) {
            this.setState({ height: window.innerHeight })
        }
        if (this.state.width !== window.innerWidth) {
            this.setState({ width: window.innerWidth })
        }
    }
    componentDidMount() {
        this.setState({ height: window.innerHeight, width: window.innerWidth })
        window.addEventListener('resize', this.updateDimensions, { passive: true })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }
    render() {
        const VideoContainer = styled.div`
            width: 100%;
            display: flex;
            align-items: center;
            overflow: hidden;
            position: relative;
            video {
                position: absolute;
            }
        `
        const Content = styled.div`
            position: relative;
            padding: ${Theme.spacing.one};
            @media(max-width: 500px) {
                padding-top: ${Theme.spacing.max};
                padding-bottom: ${Theme.spacing.max};
            }
        `
        return (
            <Section id="home">
                <VideoContainer style={{ minHeight: `${this.state.width > 500 ? this.state.height : 350}px` }}>
                    <video autoPlay="autoplay" loop="loop" muted style={{ height: `${this.state.width >= 1024 && this.state.width < 1200 ? "100%" : "auto"}` }}>
                        <source src={LoopVideo} type="video/mp4" />
                    </video>
                    <Container>
                        <Content>
                            <Heading1>{Metadata.Home.header}</Heading1>
                            <Heading3 style={{ color: Theme.color.primary }}>
                                {Metadata.Home.subheader}
                            </Heading3>
                            <Separator marginBottom={4} />
                            <GrowButtonLarge color={Theme.color.dark} backgroundColor={Theme.color.action}>
                                <Link to="/contact">
                                    <span>Tell us about your project</span>
                                    <span style={{ position: 'absolute', right: '2em', top: '50%', marginTop: '-10px' }}>→</span>
                                </Link>
                            </GrowButtonLarge>
                        </Content>
                    </Container>
                </VideoContainer>
            </Section>
        )
    }
}

export default props => (
    <StaticQuery
        query={graphql`
      query {
        background: file(relativePath: {eq: "background-poly.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              src
            }
          }
        }
      }
      `}
        render={({ background }) =>
            <HeroVideo background={background} {...props} />}
    />
)