import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import { Badge, Row, Col, Container } from 'react-bootstrap'
import styled from 'styled-components'
import PortfolioItem from 'sections/portfolio/PortfolioItem.js'
import { Heading1, Heading2, Heading3, Heading4, ParagraphText, LinkText } from 'components/text'
import { Separator } from 'components/separator'
import { ButtonStyle } from '../../constants/design'
import AnimatedHeading from 'components/animated-heading'
import { Theme } from '../../constants/variables'
import Metadata from '../../constants/metadata'
import { GrowButtonLarge } from 'components/button'
import Illustration from "../../assets/portfolio-logo-Nightlife.inline.svg"

class PortfolioSection extends React.Component {
  renderTags(tags) {
    // Container for tags included in featured project.
    const TagContainer = styled.div`
      display: inline-block;
      width: 100%;
      margin-top: ${Theme.spacing.one};
      margin-bottom: ${Theme.spacing.two};
      span:not(:first-child) {
        margin-left: ${Theme.spacing.one};
      }
      span:not(:last-child) {
        margin-right: ${Theme.spacing.one};
      }
      @media(max-width: 767px) {
        text-align: center;
        span:not(:first-child) {
          margin-left: 0;
        }
        span:not(:last-child) {
          margin-right: 0;
        }
      }
    `
    // Generate JSX for all tags
    const tagsJSX = tags.map((item, index) => {
      const tagKey = index + "_" + item + "_Tag"
      return (
        <Badge key={tagKey} variant="primary">{item}</Badge>
      )
    })
    // Render tag container + tags
    return (<TagContainer>{tagsJSX}</TagContainer>)
  }

  render() {
    // Section container for portfolio featured project.
    const Section = styled.section`
      background-color: ${Theme.color.secondary};
      padding: ${Theme.spacing.max} ${Theme.spacing.two};
      min-height: ${Theme.defaults.sectionMinHeight};
      @media(max-width: 767px) {
        padding: ${Theme.spacing.four};
      }
    `
    // Container for featured brand logo
    const LogoContainer = styled.div`
      width: 240px;
      height: auto;
      display: block;
      margin-bottom: ${Theme.spacing.two};
    `
    // Container for rendered portfolio item card
    const PortfolioItemContainer = styled.div`
      padding: 0 ${Theme.spacing.four};
      @media(max-width: 767px) {
        padding: 0;
      }
    `
    // Get first project matching graph query
    const { projects } = this.props
    const project = projects[0].node
    return (
      <Section id="featured-project">
        <Row>
          <Col md={7}>
            <PortfolioItemContainer>
              {this.renderPortfolioItem()}
            </PortfolioItemContainer>
          </Col>
          <Col md={5}>
            <Container>
              {/* Project headline */}
              <Heading1>{project.headline}</Heading1>
              <Separator marginBottom={2} />
              {/* Clickable logo */}
              <Link to={`work/${project.slug.current}`}>
                <LogoContainer>
                  <Illustration style={{ width: '100%', height: 'auto' }} />
                </LogoContainer>
              </Link>
              {/* Project subheadline */}
              <ParagraphText>{project.subheadline}</ParagraphText>
              {/* Project CTA */}
              <Link to={`work/${project.slug.current}`}>
                <LinkText hover={true}>Check out the project ⭢ </LinkText>
              </Link>
              {/* Project Tags */}
              {/* {this.renderTags(project.tags)} */}
            </Container>
          </Col>
        </Row>
      </Section>
    )
  }

  renderPortfolioItem() {
    const { projects } = this.props
    const project = projects[0].node
    const index = 0
    return (
      <PortfolioItem
        key={index}
        index={index}
        id={project.id}
        link={`/work/${project.slug.current}`}
        featured={true}
        title={project.title}
        headline={project.headline}
        subheadline={project.subheadline}
        type="col"
        coverPhoto={project.coverPhoto}
      />
    )
  }
}

export const query = graphql`
    query PortfolioHighlightQuery {
      projects: allSanityProject(filter: { title: { eq: "Nightlife" } }) {
        edges {
          node {
            id
            slug { current }
            title
            metaDescription
            headline
            subheadline
            coverPhoto { asset { url } }
            tags
          }
        }
      }
    }
    `

export default props => (
  <StaticQuery
    query={query}
    render={({ projects }) => <PortfolioSection projects={projects.edges} {...props} />}
  />
)