import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import { InnerContent, MainContainer, ImageWrapper } from 'components/container'
import ColumnContainer from 'components/column'
import { Separator } from 'components/separator'
import Metadata from '../../constants/metadata'
import { ButtonStyle } from '../../constants/design'
import { Theme } from '../../constants/variables'
import Section from 'components/section'
import { GrowButtonLarge } from 'components/button'
import { ParagraphText } from 'components/text'
import Illustration from "../../assets/illustration-about-section.inline.svg"

class AboutSection extends React.Component {
  shouldComponentUpdate() { return false }

  render() {
    const ImageCol = styled(Col)`
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
          .image-col {
              text-align: center;
          }
      }
    `
    const styles = {
      height: 'auto',
      width: '100%',
    }
    return (
      <Section id="about" style={{ backgroundColor: Theme.color.dark }}>
        <MainContainer>
          <Row>
            <ColumnContainer md={7}>
              <AnimationContainer animation="fadeIn">
                <InnerContent>
                  {Metadata.About.renderSectionTitle()}
                  <Separator marginBottom={4} />
                  <ParagraphText>
                    {Metadata.About.description}
                  </ParagraphText>
                  <GrowButtonLarge>
                    <Link to="/about">
                      <span>{Metadata.Actions.LearnMore}</span>
                      <span style={ButtonStyle.growButtonLargeArrow}>→</span>
                    </Link>
                  </GrowButtonLarge>
                </InnerContent>
              </AnimationContainer>
            </ColumnContainer>

            <ImageCol md={5}>
              <ImageWrapper className="Illustration right-padding mobile-padding">
                <Illustration style={styles} />
              </ImageWrapper>
            </ImageCol>
          </Row>
        </MainContainer>
      </Section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
    query {
      image: file(relativePath: {eq: "icons/mobile.png"}) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            src
          }
        }
      }
    }
    `}
    render={({ image, abstract }) => <AboutSection image={image} abstract={abstract} {...props} />}
  />
)