import React from 'react'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import HeroVideo from 'sections/hero/HeroVideo.js'
import AboutSection from 'sections/about/AboutSection.js'
import ServicesSection from 'sections/services/ServicesSection.js'
import PortfolioSection from 'sections/portfolio/PortfolioSection.js'
import ContactSection from 'sections/contact/ContactSection.js'
import Metadata from '../constants/metadata'

class Index extends React.Component {

  render() {
    const { site } = this.props.data
    return (
      <div>
        <Helmet>
          <title>{site.meta.title}</title>
          <meta name="description" content={site.meta.description} />
        </Helmet>
        <Layout
          isHome={true}
          useReveal={false} // TODO: SET TO TRUE FOR LAUNCH
          sections={Metadata.Navigation.Links}
        >
          <HeroVideo />
          <AboutSection />
          <ServicesSection renderFullPage={false} />
          <PortfolioSection />
          <ContactSection />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeOneQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`